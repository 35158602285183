import React, { useEffect} from 'react'

export const SitePage = ({
  children,
  className='',
  title='Portfolio'
}) => {
  useEffect(() => {
    document.title = `${title} | UxAugment`;
    window.scrollTo(0,0);
  }, [title]);

  return (
    <section className={`${className} page-section`}>
      {children}
    </section>
  )
}
