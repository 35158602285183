import React from 'react';

// import { Footer } from './Footer';
// import { Header } from './Header';
import { Helmet } from 'react-helmet';

export const Layout = ({ children, className = '' }) => {
  return (
    <React.Fragment>
      <div className={`site-container ${className}`}>{children}</div>
    </React.Fragment>
  );
};

// eslint-disable-next-line
function MetaData() {
  return (
    <Helmet>
      {/* <meta charSet="utf-8" /> */}
      {/* <title>My Title</title> */}
      {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      {/* <link rel="stylesheet" href="/css/skins/pink.css" type="text/css" /> */}

      <link
        rel="stylesheet"
        className="alternate-style"
        title="blue"
        href="/css/skins/blue.css"
        type="text/css"
        disabled
      />
      <link
        rel="stylesheet"
        className="alternate-style"
        title="green"
        href="/css/skins/green.css"
        type="text/css"
        disabled
      />
      <link
        rel="stylesheet"
        className="alternate-style"
        title="orange"
        href="/css/skins/orange.css"
        type="text/css"
        disabled
      />
      <link
        rel="stylesheet"
        className="alternate-style"
        title="pink"
        href="/css/skins/pink.css"
        type="text/css"
        disabled
      />
      <link
        rel="stylesheet"
        className="alternate-style"
        title="yellow"
        href="/css/skins/yellow.css"
        type="text/css"
        disabled
      />
    </Helmet>
  );
}
