import React from 'react';

import 'sass/_main.scss';

import { AppRoutes } from './Routes';

const App = () => {
  
  return (
    <AppRoutes />
  );
};

export default App;
