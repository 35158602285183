import React, { useState } from 'react';
import { SitePage } from 'layout/SitePage';
import { FaSearch } from 'react-icons/fa';
import { Lightbox } from 'components/modal/Lightbox';

const portfolios = [
  {
    img: '/img/portfolio/1.jpg',
    category: 'web-design',
    title: 'Web Design',
  },
  {
    img: '/img/portfolio/2.jpg',
    category: 'photography',
    title: 'Photography',
  },
  {
    img: '/img/portfolio/3.jpg',
    category: 'apps',
    title: 'Apps',
  },
  {
    img: '/img/portfolio/4.jpg',
    category: 'web-design',
    title: 'Web Design',
  },
  {
    img: '/img/portfolio/5.jpg',
    category: 'photography',
    title: 'Photography',
  },
  {
    img: '/img/portfolio/6.jpg',
    category: 'apps',
    title: 'Apps',
  },
];

const filterBtns = [
  {category: 'web-design', title: 'web design'},
  {category: 'photography', title: 'photography'},
  {category: 'apps', title: 'apps'},
];

const PortfolioItem = ({ img, category, title, onClick }) => {
  return (
    <div
      className="portfolio-item padd-15"
      data-category={category}
      onClick={onClick}
    >
      <div className="portfolio-item-inner shadow-dark">
        <div className="portfolio-img">
          <img src={img} alt={category} />
        </div>
        <div className="portfolio-info">
          <h4>{title}</h4>
          <div className="icon">
            <FaSearch />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PortfolioPage = ({ className= ''}) => {
  // eslint-disable-next-line
  const [portItems, setItems] = useState(portfolios);
  const [stateLB, setLB] = useState({
    show: false,
    index: 0,
    items: portfolios || []
  });
  const [currentFilter, setFilter] = useState('all');


  const openModal = (index) => () => {
    setLB(prev => ({ ...prev, show: true, index: index }));
  }

  const handleFilter = (filter) => () => {
    let filteredItems = portItems;
    if(filter !== 'all') {
      filteredItems = (
        portItems.filter(pItem => pItem.category === filter)
      );
    }
    setFilter(filter);
    setLB(prev => ({ ...prev, items: filteredItems }))
  }

  const renderFilters = () => {
    const all = {category: 'all', title: 'all'};
    const filters = [all, ...filterBtns];

    return filters.map(filter => (
      <button
        key={filter.category}
        type="button"
        className={
          filter.category === currentFilter ? 'btn-target ' : ''
        }
        data-filter={filter.category}
        onClick={handleFilter(filter.category)}
      >
        {filter.title}
      </button>
    ))
  };

  const renderPortfolioItems = () => {
    return stateLB?.items?.map((item, i) => (
      <PortfolioItem key={i} {...item} onClick={openModal(i)} />
    )) || null;
  }

  const lightboxConfig = () => (
    <Lightbox stateLB={stateLB} setLB={setLB} />
  )

  return (
    <React.Fragment>
    <SitePage title="Portfolio" className={`portfolio ${className}`}>
      <div className="container">
        <div className="row">
          <div className="page-title padd-15">
            <h2>Portfolio</h2>
          </div>
        </div>

        <div className="row">
          <div className="portfolio-filter padd-15">
            {renderFilters()}
          </div>
        </div>

        <div className="portfolio-items">
          {/* Portfolio Items */}
          {renderPortfolioItems()}
        </div>
      </div>
    </SitePage>

    { stateLB.show && lightboxConfig()}
    </React.Fragment>
  );
};
