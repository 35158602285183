import React from 'react';
import { SitePage } from 'layout/SitePage';
import {
  FaLaptop,
  FaImage,
  FaCode,
  FaFilm,
  FaLock,
  FaRocket,
  FaPaintBrush,
  FaNetworkWired,
  FaClipboardCheck,
  FaMailBulk,
  FaRegCreditCard,
  FaDatabase,
  FaChartLine,
  FaCloud,
} from 'react-icons/fa';

const services = [
  {
    title: 'Web Design',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo! sit amet consectetur',
    icon: <FaLaptop />,
  },
  {
    title: 'Photography',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaImage />,
  },
  {
    title: 'Web Development',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaCode />,
  },
  {
    title: 'Logo Design',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaPaintBrush />,
  },
  {
    title: 'Video Editing',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaFilm />,
  },
  {
    title: 'SEO',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaRocket />,
  },
  {
    title: 'Marketing',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaMailBulk />,
  },
  {
    title: 'Payments',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaRegCreditCard />,
  },
  {
    title: 'Networking',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaNetworkWired />,
  },
  {
    title: 'Databases',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaDatabase />,
  },
  {
    title: 'Cloud Computing',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaCloud />,
  },

  {
    title: 'Analytics',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaChartLine />,
  },
  {
    title: 'Testing',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaClipboardCheck />,
  },
  {
    title: 'Security',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex voluptas nemo fugit asperiores suscipit quo!',
    icon: <FaLock />,
  },
];

const ServiceItem = ({ icon = <div></div>, title = '', text = '' }) => {
  return (
    <div className="service-item padd-15">
      <div className="service-item-inner">
        <div className="icon">{icon}</div>
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
};

export const ServicesPage = ({ className= ''}) => {
  const renderServices = () =>
    services.map((serv, i) => <ServiceItem key={i} {...serv} />);

  return (
    <SitePage title="Services" className={`services ${className}`}>
      <div className="container">
        <div className="row">
          <div className="page-title padd-15">
            <h2>Services</h2>
          </div>
        </div>

        <div className="row">
          {/* Service Items */}
          {renderServices()}
        </div>
      </div>
    </SitePage>
  );
};
