import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { Landing } from 'pages/landing';


export const AppRoutes = () => {
  
  return (
    <Switch>
      <Route path='/' exact component={Landing} />
    </Switch>
  )
}
