import React from 'react';
import { FaCalendar } from 'react-icons/fa';

const TimeLineItem = ({
  date,
  title,
  text
}) => {
  return (
    <div className="timeline-item">
      <div className="circle-dot"></div>
      <h6 className="timeline-date">
        <FaCalendar /> {date}
      </h6>
      <div className="timeline-title">{title}</div>
      <p className="timeline-text">{text}</p>
    </div>
  )
}


export const TimeLine = ({items= []}) => {
  const renderItems = () => (
    items.map((item, i) => <TimeLineItem key={i + item.title} {...item} />)
  )

  return (
    <div className="timeline-box">
      <div className="timeline shadow-dark">
        {/* Timeline Items */}
        {renderItems()}
      </div>
    </div>
  );
};
