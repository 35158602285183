import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

export const Lightbox = ({
  stateLB = { items: [], index: 0, show: true },
  setLB = (state = { index: 0, show: false }) => {},
  onClick = () => {},
}) => {
  const { items, index } = stateLB;

  // eslint-disable-next-line
  const [current, setCurrent] = useState({
    img: items[index]?.img || '',
    caption: items[index]?.title || '',
    index: index,
    length: items.length,
  });

  const handleClose = () => {
    setLB((prev) => ({
      ...prev,
      show: false,
    }));
  };

  // "/img/portfolio/2.jpg"
  const defaultClick = (e) => e.stopPropagation();

  const handleNext = (forwards = true) => (e) => {
    e.stopPropagation();
    let nextIndex;

    if (forwards) {
      nextIndex = (current.index + 1) % current.length;
    } else {
      nextIndex = current.index - 1;
      if (nextIndex < 0) nextIndex = current.length - 1;
    }

    setCurrent((prev) => ({
      ...prev,
      img: items[nextIndex]?.img || '',
      caption: items[nextIndex]?.title || '',
      index: nextIndex,
    }));
  };

  const ModalContent = (
    <div className="lightbox open" onClick={handleClose}>
      <div className="lightbox-content" onClick={defaultClick}>
        <div className="lightbox-close" onClick={handleClose}>
          <FaTimes />
        </div>
        <img
          src={current.img}
          className="lightbox-img"
          alt={`Lightbox gallery ${current.index + 1}`}
          onClick={handleNext()}
        />

        <div className="lightbox-caption">
          <div className="caption-text">{current.caption}</div>
          <div className="caption-counter">
            {current.index + 1} of {current.length}
          </div>
        </div>
      </div>

      <div className="lightbox-controls">
        <div className="prev-item" onClick={handleNext(false)}>
          <FaChevronLeft />
        </div>
        <div className="next-item" onClick={handleNext()}>
          <FaChevronRight />
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    ModalContent,
    document.getElementById('gallery-modal')
  );
};

// #4
// 37:51
