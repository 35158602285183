import React from 'react';
import { SitePage } from 'layout/SitePage';

const blogItems = [
  {
    date: '23 Feb 2020',
    title: 'Responsive Design',
    img: '/img/blog/1.jpg',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus, fugit? Necessitatibus delectus fugiat vel reiciendis accusantium, repellat ab quis, adipisci deleniti quia in. Saepe',
    tags: ['html', 'css'],
  },
  {
    date: '14 Mar 2020',
    title: 'Creative Slideshow',
    img: '/img/blog/2.jpg',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus, fugit? ',
    tags: ['html', 'javascript'],
  },
  {
    date: '19 Apr 2020',
    title: 'Gallery Lightbox',
    img: '/img/blog/3.jpg',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus, fugit? Necessitatibus delectus fugiat vel reiciendis accusantium, repellat ab quis, adipisci deleniti quia in. Saepe',
    tags: ['javascript'],
  },
];

const BlogItem = ({
  date = '',
  img = '',
  title = '',
  description = '',
  tags = []
}) => {

  const renderTags = () => {
    return tags.map((t, i) => (
      <React.Fragment key={i}>
        <a href="/" className="tag-link">{t}</a>
        { i < tags.length - 1 && ', '}
      </React.Fragment>
    ))
  }

  return (
    <div className="blog-item padd-15">
      <div className="blog-item-inner shadow-dark">
        <div className="blog-img">
          <img src={img} alt="blog" />
          <div className="blog-date">{date}</div>
        </div>
        <div className="blog-info">
          <h4 className="blog-title">{title}</h4>
          <p className="blog-description">{description}</p>
          <div className="blog-tags">Tags : {renderTags()}</div>
        </div>
      </div>
    </div>
  );
};

export const BlogPage = ({ className= ''}) => {

  const renderBlogs = () => {
    return blogItems.map((blog, i) => (
      <BlogItem key={blog.title + i} {...blog} />
    ))
  }

  return (
    <SitePage title="Blog" className={`blog ${className}`}>
      <div className="container">
        <div className="row">
          <div className="page-title padd-15">
            <h2>Latest Blog</h2>
          </div>
        </div>

        <div className="blog-item-list">
          {/* Blog Items */}
          {renderBlogs()}
        </div>
      </div>
    </SitePage>
  );
};
