import React, { useState } from 'react';
import {
  FaHome,
  FaUser,
  FaList,
  FaBriefcase,
  FaEnvelope,
  FaComments,
  FaMoon,
  FaSun,
} from 'react-icons/fa';

import { HomePage } from './HomePage';
import { AboutPage } from './AboutPage';
import { ServicesPage } from './ServicesPage';
import { PortfolioPage } from './PortfolioPage';
import { BlogPage } from './BlogPage';
import { ContactPage } from './ContactPage';
import { Layout } from 'layout/Layout';
import { AudioPlayer } from 'components/player/AudioPlayer';

export const Landing = () => {
  const [pageStyle, setPageStyle] = useState('pink');
  const [darkMode, setDarkMode] = useState(false);
  const [openNav, setOpenNav] = useState(false);

  const [activePage, setActivePage] = useState({
    current: 'home',
    previous: '',
  });

  const openCloseDrawer = () => setOpenNav((prev) => !prev);
  const checkDrawer = () => {
    return openNav ? 'opened-drawer' : '';
  };

  const handleStyleChange = (e) => {
    const { name } = e.target;
    setPageStyle(name);
  };

  const turnOnDark = () => setDarkMode(true);
  const turnOffDark = () => setDarkMode(false);

  const handlePageChange = (page) => (e) => {
    e.preventDefault();
    setOpenNav(false);
    if(page !== activePage.current) {
      window.scrollTo(0,0);
    }

    setActivePage((ps) => ({
      previous: ps.current,
      current: page,
    }));
  };

  const checkActivePage = (page) => {
    if (page === activePage.current) {
      return 'active-page';
    } else if (page === activePage.previous) {
      return 'prev-page';
    }
    return 'inactive-page';
  };

  const checkActiveLink = (page) => {
    return page === activePage.current ? 'active' : '';
  };

  const styleSwitcher = () => {
    return (
      <div className="style-switcher">
        <h5>Style</h5>
        <ul>
          <li>
            <button
              name="pink"
              className={pageStyle === 'pink' ? 'flash-bg-pink' : ''}
              style={{ backgroundColor: '#ec1839' }}
              onClick={handleStyleChange}
            ></button>
          </li>
          <li>
            <button
              name="blue"
              className={pageStyle === 'blue' ? 'flash-bg-blue' : ''}
              style={{ backgroundColor: '#2196f3' }}
              onClick={handleStyleChange}
            ></button>
          </li>
          <li>
            <button
              name="green"
              className={pageStyle === 'green' ? 'flash-bg-green' : ''}
              style={{ backgroundColor: '#72b626' }}
              onClick={handleStyleChange}
            ></button>
          </li>
        </ul>
        <ul>
          <li>
            <button onClick={turnOffDark} style={{ backgroundColor: 'white' }}>
              <FaSun />
            </button>
          </li>
          <li>
            <button onClick={turnOnDark} style={{ backgroundColor: 'silver' }}>
              <FaMoon />
            </button>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <Layout
      className={`${pageStyle || 'pink'}-page-style ${
        darkMode ? 'dark-mode' : ''
      }`}
    >
      <div
        onClick={openCloseDrawer}
        className={`nav__side-drawer ${checkDrawer()}`}
      >
        <div className="menu">
          <div className="menu-line menu-line-1"></div>
          <div className="menu-line menu-line-2"></div>
          <div className="menu-line menu-line-3"></div>
        </div>
      </div>

      <div className="side-nav">
        {/* <div className="side-nav__toggler">
          <span></span>
        </div> */}

        <div className="company-logo">
          <a href="/">UxAugment</a>
        </div>

        <ul className="side-nav__list">
          <li className="side-nav__item">
            <a
              href="/"
              onClick={handlePageChange('home')}
              className={checkActiveLink('home')}
            >
              <FaHome /> Home
            </a>
          </li>
          <li className="side-nav__item">
            <a
              href="/"
              onClick={handlePageChange('about')}
              className={checkActiveLink('about')}
            >
              <FaUser /> About
            </a>
          </li>
          <li className="side-nav__item">
            <a
              href="/"
              onClick={handlePageChange('services')}
              className={checkActiveLink('services')}
            >
              <FaList /> Services
            </a>
          </li>
          <li className="side-nav__item">
            <a
              href="/"
              onClick={handlePageChange('portfolio')}
              className={checkActiveLink('portfolio')}
            >
              <FaBriefcase /> Portfolio
            </a>
          </li>
          <li className="side-nav__item">
            <a
              href="/"
              onClick={handlePageChange('blog')}
              className={checkActiveLink('blog')}
            >
              <FaComments /> Blog
            </a>
          </li>
          <li className="side-nav__item">
            <a
              href="/"
              onClick={handlePageChange('contact')}
              className={checkActiveLink('contact')}
            >
              <FaEnvelope /> Contact
            </a>
          </li>
        </ul>

        <div className="copyright-text">&copy; 2020 UxAugment</div>
      </div>

      <div className="site-content">
        {styleSwitcher()}
        <HomePage className={checkActivePage('home')} />
        <AboutPage className={checkActivePage('about')} />
        <ServicesPage className={checkActivePage('services')} />
        <PortfolioPage className={checkActivePage('portfolio')} />
        <BlogPage className={checkActivePage('blog')} />
        <ContactPage className={checkActivePage('contact')} />
      </div>

      <AudioPlayer/>
    </Layout>
  );
};
