import React from 'react';
import { SitePage } from 'layout/SitePage';

import {
  FaTwitter,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn
} from 'react-icons/fa'

export const HomePage = ({ className= ''}) => {
  return (
    <SitePage title='Home' className={`home ${className}`}>
      
      <div className="container">
        <div className="intro">
          <div className="intro__img shadow-dark">
            <img src='/img/portfolio/me.jpeg' alt="profile"/>
          </div>
          <h1>Francisco Diaz</h1>
          <p>I'm a Software Developer</p>

          <div className="social-links">
            <a href="/">
              <FaTwitter/>
            </a>
            <a href="/">
              <FaFacebookF/>
            </a>
            <a href="/">
              <FaInstagram/>
            </a>
            <a href="/">
              <FaLinkedinIn/>
            </a>
          </div>
        </div>

      </div>

      

    </SitePage>
  )
}
