import React from 'react';
import { SitePage } from 'layout/SitePage';
import { TimeLine } from 'components/timeline';

const education = [
  {
    date: '2018 - 2019',
    title: 'Bachelors In Computer Science',
    text:
      'Finished my degree focusing on web development and mobile development',
  },
  {
    date: '2016 - 2018',
    title: 'Switched Majors',
    text: 'Took various courses in preparation for my major',
  },
  {
    date: '2015 - 2016',
    title: 'Studied Mechanical Engineering',
    text: 'Initial focus of study',
  },
];

const experience = [
  {
    date: '2019 - Present',
    title: 'Full Stack Developer',
    text: 'Front to back web development from concept design to deployment',
  },
  {
    date: '2017 - 2019',
    title: 'Software Engineering',
    text:
      'Used C# & .Net to build a server to handle multi-user communications',
  },
  {
    date: '2015 - 2018',
    title: 'Mobile Game Developer',
    text:
      'Used Unity Engine to build mobile ready video game applications for Android and iPhone.',
  },
];

export const AboutPage = ({ className= '' }) => {
  return (
    <SitePage title="About" className={`about ${className}`}>
      <div className="container">
        <div className="row">
          <div className="page-title padd-15">
            <h2>About Me</h2>
          </div>
        </div>

        <div className="row">
          <div className="about-content padd-15">
            <div className="row">
              <div className="about-text padd-15">
                <h3>
                  I'm Francisco Diaz and I'm <span>Software Developer</span>
                </h3>
                <p>
                  Hi! My name is Francisco Diaz. I am a Software Developer
                  with a focus in front to back web and mobile development. I'm
                  very passionate and dedicated to my work. With 6+ years of
                  experience as a professional, I have acquired the skills and
                  knowledge necessary to make your projects a reality. I enjoy
                  every step of the design process, from discussion and
                  collaboration.
                </p>
              </div>
            </div>

            <div className="row about-details">
              {/* PERSONAL */}
              <div className="personal-info padd-15">
                <div className="row">


                  <div className="info-item padd-15">
                    <p>Website :</p>
                    <span>www.uxaugment.com</span>
                  </div>
                  <div className="info-item padd-15">
                    <p>Email :</p>
                    <span>
                      <a href="mailto: cisco@uxaugment.dev">
                        cisco@uxaugment.dev
                      </a>
                    </span>
                  </div>
                  <div className="info-item padd-15">
                    <p>Degree :</p>
                    <span>B.S. Computer Science</span>
                  </div>
                  {/* <div className="info-item padd-15">
                    <p>Phone :</p>
                    <span>+01 650 555 5555</span>
                  </div> */}
                  <div className="info-item padd-15">
                    <p>City :</p>
                    <span>San Francisco</span>
                  </div>
                  <div className="info-item padd-15">
                    <p>Freelance :</p>
                    <span>Available</span>
                  </div>
                </div>
              </div>

              {/* SKILLS */}
              <div className="skills padd-15">
                <div className="row">
                  <div className="skill-item">
                    <h5>Javascript</h5>
                    <div className="progress">
                      <div
                        className="progress-in"
                        style={{ width: '100%' }}
                      ></div>
                      <div className="skill-percent">100%</div>
                    </div>
                  </div>
                  <div className="skill-item">
                    <h5>C++</h5>
                    <div className="progress">
                      <div
                        className="progress-in"
                        style={{ width: '100%' }}
                      ></div>
                      <div className="skill-percent">100%</div>
                    </div>
                  </div>
                  <div className="skill-item">
                    <h5>C#</h5>
                    <div className="progress">
                      <div
                        className="progress-in"
                        style={{ width: '100%' }}
                      ></div>
                      <div className="skill-percent">100%</div>
                    </div>
                  </div>
                  <div className="skill-item">
                    <h5>Java</h5>
                    <div className="progress">
                      <div
                        className="progress-in"
                        style={{ width: '100%' }}
                      ></div>
                      <div className="skill-percent">100%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="buttons padd-15">
                <button type='button' className="link-btn">
                  Download CV
                </button>
                <a href="mailto: cisco@uxaugment.dev" className="link-btn">
                  Hire Me
                </a>
              </div>
            </div>

            <div className="row">
              <div className="education padd-15">
                <h3 className="title">Education</h3>

                <div className="row">
                  <TimeLine items={education} />
                </div>
              </div>
              <div className="experience padd-15">
                <h3 className="title">Experience</h3>

                <div className="row">
                  <TimeLine items={experience} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SitePage>
  );
};
