import React, { Component, useState } from 'react';
import { FaBackward, FaForward, FaPlay, FaPause } from 'react-icons/fa';

export class Audio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      duration: null,
    };
  }

  handlePlay() {
    this.audio.play();
  }

  handleStop() {
    this.audio.currentTime = 0;
    this.slider.value = 0;
    this.audio.pause();
  }

  componentDidMount() {
    this.slider.value = 0;
    this.currentTimeInterval = null;

    // Get duration of the song and set it as max slider value
    this.audio.onloadedmetadata = function () {
      this.setState({ duration: this.audio.duration });
    }.bind(this);

    // Sync slider position with song current time
    this.audio.onplay = () => {
      this.currentTimeInterval = setInterval(() => {
        this.slider.value = this.audio.currentTime;
      }, 500);
    };

    this.audio.onpause = () => {
      clearInterval(this.currentTimeInterval);
    };

    // Seek functionality
    this.slider.onchange = (e) => {
      clearInterval(this.currentTimeInterval);
      this.audio.currentTime = e.target.value;
    };
  }

  render() {
    const src = '/music/NDespacito.mp3';

    return (
      <div>
        <audio
          ref={(audio) => {
            this.audio = audio;
          }}
          src={src}
        />

        <input
          type="button"
          value="Play"
          onClick={this.handlePlay.bind(this)}
        />

        <input
          type="button"
          value="Stop"
          onClick={this.handleStop.bind(this)}
        />

        <h1>
          <input
            ref={(slider) => {
              this.slider = slider;
            }}
            type="range"
            name="points"
            min="0"
            max={this.state.duration}
            className="player-slider"
          />{' '}
        </h1>
        <p></p>
      </div>
    );
  }
}

export function AudioPlayer() {
  const [player, setPlayer] = useState(null);

  const [songInfo, setSongInfo] = useState({
    duration: '',
    isPlaying: false,
    progressPercent: 0,
    title: 'Nightcore | Despacito'
  });

  const { isPlaying } = songInfo;

  const src = '/music/NDespacito.mp3';

  const getDuration = () => {
    setSongInfo((prev) => ({ ...prev, duration: player.duration }));
  };

  const handlePlay = () => {
    if (isPlaying) {
      player.pause();
    } else {
      player.play();
    }
    setSongInfo((prev) => ({ ...prev, isPlaying: !prev.isPlaying }));
  };

  const updateProgress = (e) => {
    const { duration, currentTime } = e.target;
    const progPercent = `${(currentTime / duration) * 100 }%`;
    
    setSongInfo((prev) => ({ ...prev, progressPercent: progPercent }));
    // progress.style.width = `${progressPercent || 0}%`;
  };
  function setProgress(e) {
    const width = e.target.clientWidth;
    const clickX = e.nativeEvent.offsetX;

    const duration = songInfo.duration;
    const seekTo = (clickX / width) * duration;
    if(isFinite(seekTo)){
      player.currentTime = seekTo;
    }
  }

  const handleEnd = (e) => {
    player.pause();
    player.currentTime = 0;
    setSongInfo(prev => ({ ...prev, isPlaying: false}))
  }

  return (
    <div
      className={`music-container ${isPlaying ? 'play' : ''}`}
      id="music-container"
    >
      <div className="music-info">
        <h4 id="title">{songInfo.title}</h4>
        <div
          onClick={setProgress}
          className="progress-container" id="progress-container">
          <div
            className="progress"
            style={{ width: songInfo.progressPercent || 0 }}
            id="progress"
          ></div>
        </div>
      </div>

      <audio
        onLoadedMetadata={getDuration}
        onTimeUpdate={updateProgress}
        onEnded={handleEnd}
        ref={setPlayer}
        src={src}
        id="audio"
      ></audio>

      <div className="cover-imgbox">
        <img src="/img/music/beach.jpg" alt="music cover" id="cover" />
      </div>

      <div className="navigation">
        <button id="prev" className="action-btn">
          <FaBackward />
        </button>
        <button
          id="play"
          onClick={handlePlay}
          className="action-btn action-btn-big"
        >
          {!songInfo.isPlaying? <FaPlay /> : <FaPause/>}
        </button>
        <button id="next" className="action-btn">
          <FaForward />
        </button>
      </div>
    </div>
  );
}
