import React from 'react';
import { SitePage } from 'layout/SitePage';
import { FaPhone, FaMapMarker, FaEnvelope } from 'react-icons/fa';

const contactInfo = [
  {
    title: 'Call me at',
    text: '+1 650 555 5555',
    icon: <FaPhone />,
  },
  {
    title: 'Office',
    text: '1 Escanor Blvd, San Francisco, CA',
    icon: <FaMapMarker />,
  },
  {
    title: 'Email',
    text: 'cisco@uxaugment.dev',
    icon: <FaEnvelope />,
  },
];

const ContactItem = ({ title = '', text = '', icon = <span></span> }) => {
  return (
    <div className="contact-info-item padd-15">
      <div className="icon">{icon}</div>
      <h4>{title}</h4>
      <p>{text}</p>
    </div>
  );
};

export const ContactPage = ({ className= ''}) => {
  const renderContactInfo = () => {
    return contactInfo.map((info, i) => <ContactItem key={i} {...info} />);
  };

  return (
    <SitePage title="Contact Me" className={`contact ${className}`}>
      <div className="container">
        <div className="row">
          <div className="page-title padd-15">
            <h2>Contact Me</h2>
          </div>
        </div>

        {/* Main Content */}
        <div className="row">{renderContactInfo()}</div>

        {/* Contact Form */}
        <div className="row">
          <form className="contact-form padd-15">
            <div className="row">
              {/* Form Item */}
              <div className="form-item col-6 padd-15">
                <div className="form-group">
                  <input
                    placeholder="Name*"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>

              {/* Form Item */}
              <div className="form-item col-6 padd-15">
                <div className="form-group">
                  <input
                    placeholder="Email*"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {/* Form Item */}
              <div className="form-item col-12 padd-15">
                <div className="form-group">
                  <input
                    placeholder="Subject*"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {/* Form Item */}
              <div className="form-item col-12 padd-15">
                <div className="form-group">
                  <textarea placeholder="Your Message . . ." className="form-control" />
                </div>
              </div>
            </div>

            <div className="row">
              {/* Form Item */}
              <div className="col-12 padd-15">
                <button disabled={true} type="submit" className="link-btn">
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </SitePage>
  );
};
