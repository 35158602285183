import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import history from './browserHistory';
import * as serviceWorker from './serviceWorker';
import { Router as BrowserRouter } from 'react-router-dom';


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter history={history}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
